@import url('https://fonts.googleapis.com/css2?family=Della+Respira&family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100;1,200;1,300;1,400;1,500&display=swap');
@tailwind base;

/**
 * Begin global overrides.
 *
 * You can use `@apply` or `theme()` to access existing Tailwind CSS styles or variables.
 * (See https://tailwindcss.com/docs/adding-base-styles/#using-css)
 */


body {
  display: block;
  position: relative;
  margin: 0;
  font-family: "Josefin Sans", sans-serif;
}

/* TODO: update font import properly  */

#name {
  font-family: "Della Respira";
}


.footer {
  position: absolute;
  margin-top: 20pt;
  bottom: 0;
  width: 100%;
}

/* End global overrides. */

@tailwind components;

.tooltip {
  @apply invisible absolute;
}

.has-tooltip:hover .tooltip {
  @apply visible z-50;
}

@tailwind utilities;
